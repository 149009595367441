<template>
  <v-dialog v-model="dialog" max-width="80%">
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <span>เพิ่ม{{ $t("non_im.city") }}</span>
        </v-col>
        <v-col cols="12" md="12">
          <span>{{ $t("non_im.country") }}</span>
          <v-autocomplete
            v-model="selectedcountry"
            :items="country"
            :item-text="EngLang ? 'name_en' : 'name_th'"
            item-value="id"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <span>{{ $t("non_im.fieldnameTH") }}</span>
          <v-text-field v-model="form.name_th" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>{{ $t("non_im.fieldnameEN") }}</span>
          <v-text-field v-model="form.name_en" solo dense></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn v-if="isEdit" @click="editdata()" dark color="primary"
            >แก้ไข
          </v-btn>
          <v-btn v-else @click="submit()" dark color="primary">บันทึก </v-btn>
          <v-btn @click="cancel()">ยกเลิก</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    EngLang: Boolean,
    form: Object,
    country: Object,
  },
  data() {
    return {
      dialog: false,
      form: {
        name_th: "",
        name_en: "",
      },
      selectedcountry: null,
    };
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === true) {
          //   this.getRewardById(val.id);
          //   console.log(val.id);
          // this.form =
        }
      },
      deeps: true,
    },
    "form.mCountryId"(val) {
      this.selectedcountry = val;
    },
  },
  methods: {
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
        mCountryId: this.selectedcountry,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/city`,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async editdata() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
        mCountryId: this.selectedcountry,
      };
      const id = this.$route.query.id;
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/city/${this.form.id}`,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    cancel() {
      this.$emit("closeDialog");
    },
  },
};
</script>